import React from 'react';

const Redirect = React.lazy(() => import('../../components/Redirect'));

export default function LegacyBooking() {
  return (
    <body className="absolute flex items-center justify-center h-full bg-primary">
      <Redirect label="Go to booking" />
    </body>
  );
}
